$fontFamily: "Roboto", "Helvetica", "Arial", sans-serif;

// Colors - Start
$primaryColor1: #556CD5;
$primaryColor2: #481db4;
$primaryColor3: #9eaef5;
$primaryColor4: #423D44;

$primaryColor5: #1E1E1E;
$primaryColor6: #272727;
$primaryColor7: #2196F3;
$primaryColor8: #d5dbdf;
$primaryColor9: #000000bd;
$primaryColor10: #D9D9D9;
$primaryColor11: #ef6c00;

$bgColor: #373738;
$containerBgColor: #68696b;
$assetTitleColor: #6F7B77;

$white: #ffffff;
$red: #ff0000;
$green: #0f5c26;
$lightGreen: #4caf50;
$greyTransparent: #00000099;
$logoutBtnBgColor: #8f8a8a69;
$logoutBtnColor: #383434;
$blue: #29ABE2;
$grey: #808080;
// Colors - End

$thicknessUltraThin: 1px;
$thicknessThin: 2px;
$thicknessMedium: 5px;
$thicknessThick: 10px;
$thicknessUltraThick: 15px;
$thicknessMegaThick: 20px;

$paperFoldThick: 30px;

// Font Sizes
$fontSizeUltraSmall: 9px;
$fontSizeSmall: 10px;
$fontSizeMedium: 12px;
$fontSizeLarge: 15px;
$fontSizeExtraLarge: 18px;
$fontSizeUltraLarge: 24px;
$fontSizeMegaLarge: 36px;

// 
$standardPadding: $thicknessThick;

// Top Header -
$headerHeight: 65px;

// Bottom Bar - 
$bottomHeight: 45px;

// Global Style
$border-style: $thicknessUltraThin solid grey;
$border-style-thick: $thicknessThick solid $primaryColor8;
$border-color: grey;
$black: #000;
$white: #fff;
$transparentBlack: rgba(0, 0, 0, 0.5);
$Transparent: rgba(0, 0, 0, 0);

$selectedMenuColor: #2297f3;
$selectedMenuBgColor: #e3f2fd;

$borderTop: #5872af33;
$borderRight: #4c94d333;
$borderLeft: #8f9ee4;
$borderBottom: #3f64b933;

// Shadows
$shadowStyle1: 0px 0px 4px 1px $black;

// Gradients
$gradientStyle1: linear-gradient($transparentBlack 0, $Transparent $thicknessThick);
$gradientStyle2: linear-gradient(45deg, $white 50%, $primaryColor8 50%);
$gradientStyle3: linear-gradient($Transparent $thicknessThick calc(100% - $thicknessThick), $transparentBlack 100%);

$size200: 200px;
$fontWeightLight: 400;
$width100: 100px;
$size50: 50px;
$height400: 400px;
$width144: 144px;
$height144: 144px;