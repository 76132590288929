@import "../../styles/cssConfig.scss";

.loading {
	margin-top:10em;
	justify-content: center;
	align-items: center;
	flex: 1;
  }
  .box {
	background-color: $primaryColor5;
	padding: $thicknessMegaThick;
  }