@import "../../styles/cssConfig.scss";

.preLogoutMessage, .logoutMessage {
	color: $white;
	background-color: $primaryColor5;
	display:inline-block;
	max-width: 320px;
	min-height:240px;
	padding: $thicknessUltraThick;
	position:absolute;
	left:50%;
	top:50%;
	transform:translate(-50%, -50%);
	button {
		position:absolute;
		right: $thicknessMegaThick;
		bottom: $thicknessMegaThick;
	}
}
