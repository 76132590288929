@import "../../styles/cssConfig.scss";
@import "../../styles.module.scss";

.assets {
	height: calc(100% - 2 * $thicknessUltraThick);
	width: calc(100% - 2 * $thicknessMegaThick);
	padding: $thicknessUltraThick $thicknessMegaThick;
	display: flex;
	flex-direction: column;

	.header-bar {
		.top-bar {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: $thicknessMedium $thicknessUltraThick;
			background: $primaryColor6;
			color: $white;
			box-shadow: $shadowStyle1;
			min-height: 5 * $thicknessThick;

			.title {
				font-weight: 400;
			}

			>div {
				display: flex;
				align-items: center;
			}
		}

		.search-bar {
			display: flex;
			align-items: center;
			padding: $thicknessThick $thicknessUltraThick;
			min-height: 5 * $thicknessThick;
			background: $primaryColor5;
			background-image: $gradientStyle1;
			column-gap: 2 * $thicknessThick;

			label,
			svg {
				color: $white;
			}

			span.searched-asset-count {
				color: $white;
				padding-left: $thicknessUltraThick;
			}

			.filters {
				div {
					color: $primaryColor1;
					border: $thicknessUltraThin solid $primaryColor1;
					border-radius: $thicknessMedium;
					margin-left: $thicknessThick;
					margin-bottom: $thicknessThick;
					transform: translate(0, calc($thicknessThick / 2));
					padding-left: $thicknessThick;
					display: inline-block;

					button {
						border: none;
						padding: 0;
						min-width: calc(2 * $thicknessMegaThick);
						min-height: calc(2 * $thicknessMegaThick);
						margin: calc($thicknessMedium/-2);

						>span {
							margin: 0;

							svg {
								color: $primaryColor1;
								margin: 0;
							}
						}

						&:hover {
							background-color: transparent;
						}
					}
				}
			}
		}

		.view-bar {
			display: flex;
			align-items: center;
			margin-top: $thicknessUltraThick;
			background: $primaryColor6;
			padding: $thicknessThick $thicknessUltraThick;

			label,
			svg {
				color: $white;
			}

			.view-type {
				display: flex;
				align-items: center;
				column-gap: 3 * $thicknessThick;
				color: $white;

				.view-text,
				.asset-count {
					color: $white;
				}

				>div {
					display: flex;
					align-items: center;
				}
			}

			.sort-select {
				margin-left: auto;
				display: flex;
				align-items: center;
				column-gap: 2 * $thicknessThick;

				div {
					div {
						background-color: unset;

						div {
							color: $white;
							padding: 0px ($paperFoldThick+$thicknessThin) 0 0;
							margin: $thicknessMedium 0 $thicknessMedium $thicknessThick;
						}
					}
				}

				.sort-order {
					column-gap: $thicknessThick;
					display: flex;
					align-items: center;
					color: $white;
				}
			}
		}
	}

	.asset-view {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: $thicknessUltraThick;
		background: $primaryColor5;
		background-image: $gradientStyle1;
		overflow-y: auto;

		.no-data {
			color: $white;
			padding: 5 * $thicknessThick;
			text-align: center;
		}

		.loader {
			margin: (5 * $thicknessMegaThick) 0 0 calc((100% - 2 * $thicknessUltraThick) / 2);
			position: absolute;
			z-index: 4;
		}
	}

	.footer-bar {
		display: flex;
		align-items: center;
		margin-top: auto;
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: 101;
		padding: $thicknessThick $thicknessMegaThick $thicknessThick $thicknessThick;
		box-sizing: border-box;
		justify-content: space-between;
		background-color: $primaryColor6;

		.selected-items-count {
			margin-left: $thicknessThick;
		}

		>div {
			display: flex;
			align-items: center;
			color: $white;

			.select-group {
				display: flex;
				align-items: center;
			}
		}
	}

	> :first-child {
		column-gap: 2 * $thicknessThick;
	}

	button {
		margin-left: $thicknessUltraThick;
	}
}

.digitalAssetContent {
	overflow-x: hidden;
	width: 100%;

	.modalButton {
		border: $border-style;
		padding: $thicknessMedium $thicknessUltraThick * 2;
		color: $white;
		font-weight: bold;
		box-shadow: none;

		&:hover {
			background: none;
			box-shadow: none;
		}
	}

	.actionContainer {
		padding: $thicknessThick 0;
	}
}

.imageList {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-x: hidden;
	padding: $thicknessThick * 2 0 0;

	.assetContainer {
		padding-top: 20px;
	}
}

.scrollableContainer {
	max-height: 350px;
	min-height: 300px;
	overflow-y: scroll;
}

.digitalListItem {
	margin: 0 0 $thicknessThick 0;

	.assetProperties {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 0;
		list-style-type: none;
		padding: $thicknessThick 0 0 0;
		margin: 0;
		font-size: $fontSizeMedium;
	}

	.assetPropertiesContainer {
		padding: 0 $thicknessThick $thicknessThick;
	}

	.assetTitleInput {
		width: 100%;
	}

	.thumbnail {
		width: 100%;
		height: 100px;
		object-fit: cover;
	}

	.closeButton {
		padding: 0 $thicknessUltraThick 0 0px;
		color: $white;
	}

	.errorInput {
		background: $bgColor;
		color: $red;
		width: 100%;
		border-radius: $thicknessThin;

		input {
			color: $red;
			-webkit-text-fill-color: $red;
		}

		svg {
			color: $red;
		}
	}

	.errorBorder {
		border: 2px solid red;
	}
}

.edit-asset {
	width: 100%;

	div {
		display: block;
		margin-bottom: 10px;
	}

	div:first-child {
		text-align: center;
		background: $black;
		padding-top: 4px;
	}

	.circle-icon {
		fill: $white;
		position: absolute;
		top: 11%;
		right: 3%;
	}

	.info-icon {
		fill: $white;
		position: absolute;
		top: 42%;
		right: 3%;
	}

	.thumbnail {
		width: 500px;
		height: 100%;
	}
}

input.hiddenInput {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1;
	overflow: hidden;
	position: absolute;
	bottom: 0;
	left: 0;
	white-space: nowrap;
	width: 1;
}

.tags-modal-box {
	padding: $thicknessMedium $thicknessUltraThick;
	width: 100%;

	label {
		color: $white !important;
	}

	.tags-divider {
		margin-bottom: $thicknessMegaThick;
	}
}

.deleteModal {
	padding: $thicknessMedium $thicknessUltraThick;
}