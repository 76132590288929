@import "../../styles/cssConfig.scss";

.edit-asset {
  width: 100%;

  .thumbnail {
    width: $width144;
    height: $height144;
    object-fit: cover;
  }

  .modalButton {
    &:hover {
      background: none;
      box-shadow: none;
    }
  }

  div {
    width: 100%;
    margin-bottom: $thicknessThick;
  }

  div:first-child {
    text-align: center;
    background: $black;
    width: 100%;
    height: $thicknessMegaThick * 8;
  }

  .circle-icon {
    cursor: pointer;
    color: $white;
    position: absolute;
    right: $thicknessThick;
  }

  .info-icon {
    fill: $white;
    position: absolute;
    top: 185px;
    right: $fontSizeExtraLarge;
  }

  img {
    object-fit: contain;
    position: relative;
    top: $thicknessThick - 2;
    width: auto;
    height: 90%;
  }

  video {
    height: 100%;
    object-fit: contain;
  }

  .menu-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .video-element {
    transition: transform 0.3s ease;
  }

  .mirrored {
    transform: scaleX(-1) !important;
  }

  label {
    color: $white !important;
  }
}

.setMaxHeight {
  max-height: $height400;
}

input.hiddenInput {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  visibility: hidden;
}