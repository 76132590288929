@import "../../../styles/cssConfig.scss";

.item-renderer {
  position: relative;
  width: $size200;
  height: 10 * $fontSizeExtraLarge;
  background: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: $thicknessThick;

  border: $thicknessUltraThin solid $black;
  border-radius: $thicknessMedium;
  padding: $thicknessMedium;
  margin: $thicknessMedium;


  .item-overlay {
    position: absolute;
    z-index: $thicknessThin;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    border-radius: inherit;

    .date-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: $fontSizeMedium;
      
      .select-checkbox {
        color: $primaryColor7;
        justify-content: flex-start;
        border-radius: 0;
        border-top-left-radius: inherit;
        width: 4 * $thicknessThick;
      }
      .date-level {
        width: $size50;
        font-weight: bold;
        display: inline-block;
      }
    }
    .background {
      position: relative;
      padding: $thicknessThin;
      margin-left: $thicknessThick;
      p {
        width: calc(100% - $thicknessThick - $thicknessThin);
        overflow: hidden;
        height: 6 * $thicknessThick;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      div {
        margin-top: inherit;
        background-color: lightgrey;
        width: fit-content;
      }
    }
  
    .article-info {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      color: $black;
      border-radius: 0;
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
      margin-left: $thicknessThick;
      .language {
        background-color: $primaryColor8;
        width: 20%;
        text-align: center;
      }
      .status{
        display: flex;
        flex-direction: row;
        font-size: $fontSizeMedium;
        align-items: center;
		svg {
			margin-right: $thicknessMedium;
		}
        .status-red {
          color:$red
        }
        .status-green {
          color:$green
        }
        .status-blue {
          color:$primaryColor7
        }
      }
      .content-center {
        color: $primaryColor7;
      }

      .options-active {
        color: $primaryColor7;
      }
      
      .options-inactive {
        color: $grey;
      }
    }
  }

  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    top:0;
    width: $paperFoldThick;
    height: $paperFoldThick;
    border-bottom: 2px solid $primaryColor8;
    border-left: 1px solid $primaryColor8;
    background: $gradientStyle2;
    border-top-right-radius: $thicknessMedium;
  }
}

.list-item-renderer {
  width: 100%;
  flex-direction: row;
  position: relative;
  background-color: $white;
  align-items: center;
  justify-content: center;
  border-bottom: $black;
  padding: $thicknessThick $thicknessThick;
  background-image: $gradientStyle3;

  .list-article-info {
    background-color: $white;
    align-items: center;
    .content-column {
      width: 5 * $thicknessThick;
      .list-header {
        width: calc(100% - 9 * $paperFoldThick);
        font-size: $fontSizeMedium;
        font-weight: $fontWeightLight;
        color: $border-color;
      }   
      .list-title {
        width: calc(100% - 9 * $paperFoldThick);
        font-size: $fontSizeMedium;
          overflow: hidden;
          height: 3 * $thicknessThick;
          text-overflow: ellipsis;
          white-space: nowrap;
      }
      .list-date {
        font-size: $fontSizeMedium;
      }
      .language {
        background-color: $primaryColor8;
        width: 20%;
        text-align: center;
        font-size: $fontSizeMedium;
      }
    }    
    
    .status{
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: $fontSizeMedium;
      svg {
      	margin-right: $thicknessMedium;
      }

      .status-red {
        color:$red
      }
      .status-green {
        color:$green
      }
      .status-blue {
        color:$primaryColor7
      }
    }
    
    .list-thumbnail {
      width: 100%;
      height: 100%;
    }

    .content-center {
      color: $primaryColor7;
      .select-checkbox {
        color: $primaryColor7;
      }
  
    }
    .options-active {
      color: $primaryColor7;
    }
    
    .options-inactive {
      color: $grey;
    }
    .padding-thick {
      padding: $thicknessThick;
    }
  }

  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    top:0;
    width: $paperFoldThick;
    height: $paperFoldThick;
    border-bottom: 2px solid $primaryColor8;
    border-left: 1px solid $primaryColor8;
    background: $gradientStyle2;
  }
}
