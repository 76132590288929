@import "../../styles/cssConfig.scss";
@import "../../styles.module.scss";

.uploadContainer {
  margin-top: $thicknessThick;
  padding-left: $standardPadding;
  padding-right: $standardPadding;
}

.uploading {
  color: $primaryColor5;
}

.errorText {
  color: $red;
}

.successText {
  color: $lightGreen;
}

.progressBar {
  margin-top: $thicknessUltraThin;
}
