@import "../../styles/cssConfig.scss";
@import "../../styles.module.scss";

.articles {
  height: calc(100% - 2 * $thicknessUltraThick);
  width: calc(100% - 2 * $thicknessMegaThick);
  padding: $thicknessThin $thicknessMegaThick;
  display: flex;
  flex-direction: column;

  .no-data {
    color: $white;
    padding: 5 * $thicknessThick;
    text-align: center;
  }

  .header-bar {

    .top-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $thicknessMedium $thicknessUltraThick;
      background: $primaryColor6;
      color: $white;
      min-height: 4 * $thicknessThick;

      >div {
        display: flex;
        align-items: center;
      }
    }

    .search-bar {
      display: flex;
      align-items: center;
      padding: $thicknessMedium $thicknessUltraThick;
      background-image: $gradientStyle1;
      min-height: 5 * $thicknessThick;
      background-color: $primaryColor5;
      color: $white;

      label,
      svg {
        color: white;
      }

      .filter-select {
        width: 10 * $thicknessUltraThick;
        margin-left: $thicknessThick;

        label {
          background-color: $primaryColor5;
          padding: 0 $thicknessMedium;
          left: calc(-1 * $thicknessMedium);
        }
      }

      .filters {
        div {
          color: $primaryColor1;
          border: $thicknessUltraThin solid $primaryColor1;
          border-radius: $thicknessMedium;
          margin-left: $thicknessThick;
          margin-bottom: $thicknessThick;
          transform: translate(0, calc($thicknessThick / 2));
          padding-left: $thicknessThick;
          display: inline-block;

          button {
            border: none;
            padding: 0;
            min-width: calc(2 * $thicknessMegaThick);
            min-height: calc(2 * $thicknessMegaThick);
            margin: calc($thicknessMedium/-2);

            >span {
              margin: 0;

              svg {
                color: $primaryColor1;
                margin: 0;
              }
            }
          }
        }
      }
    }

    .view-bar {
      display: flex;
      align-items: center;
      margin-top: 2 * $thicknessThin;
      background-color: $primaryColor6;
      padding: $thicknessMedium $thicknessUltraThick;

      label,
      svg {
        color: $white;
      }

      .view-type {
        display: flex;
        align-items: center;
        column-gap: 3 * $thicknessThick;
        color: $white;

        >div {
          display: flex;
          align-items: center;
        }
      }

      span {
        margin-right: $thicknessUltraThick;
      }

      legend>span {
        margin-right: 0;
      }

      .sort-select {
        margin-left: auto;
        display: flex;
        align-items: center;
        column-gap: 2 * $thicknessThick;
        color: $white;

        .sort-order {
          column-gap: $thicknessThick;
          display: flex;
          align-items: center;
        }

        .select-input {
          background: transparent;
          color: $white;
        }
      }
    }
  }

  .article-view {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    background: $primaryColor5;
    padding: $thicknessMedium;
    flex-grow: 1;
    align-content: flex-start;
    ;

    .loader {
      margin-left: calc((100% - 2 * $thicknessUltraThick) / 2);
    }
  }

  .footer-bar {
    display: flex;
    align-items: center;
    margin-top: $thicknessThick;
    margin-bottom: 2px;
    background-color: $primaryColor6;
    padding: 3 * $thicknessThin $thicknessUltraThick;
    justify-content: space-between;
    color: $white;
    margin-top: auto;

    > :first-child {
      display: flex;
      align-items: center;

      .select-checkbox {
        color: $primaryColor7;
      }

      >div {
        min-width: 5 * $paperFoldThick;
      }
    }
  }
}

.filterModal {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: $thicknessMedium $thicknessUltraThick;

  label {
    color: $white;
    text-transform: capitalize;
  }

  fieldset {
    border: $thicknessThin solid $white;

    legend {
      color: $white;
    }
  }

  .filterError {
    color: $red;
  }

  .filterByDate {
    width: 100%;
    height: $paperFoldThick;
    color: currentColor;
    background-color: transparent;
    border-radius: 2 * $thicknessThin;
    border: $thicknessUltraThin solid rgba($white, 0.23);
    text-align: center;
    padding: $thicknessThick 0;
  }

  .filterByDateContainer {
    width: 100%;
    display: flex;
    flex-flow: column;
  }
}

.deleteModalContent {
  padding: $thicknessMedium $thicknessUltraThick;
}