@import "../../styles/cssConfig.scss";

.option-menu {
    div {
        ul {
            width: $size200;
            background-color: $primaryColor1;
            color: $white;

            li, label {
                justify-content: space-between;
                background-color: $primaryColor1;
                color: $white;
            }
            :hover {
                background-color: $primaryColor2;
            }
        }
    }
}