@import "../../styles/cssConfig.scss";

.topBarBgColor {
  background-color: lightblue;

  .welcomeTopbar {
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .Search {
      flex-direction: column;
      position: relative;
      border-radius: 10px;
      background-color: $white;

      &:hover {
        background-color: $white;
      }

      margin-left: 0;
      width: 100%;

      @media (min-width: 600px) {
        margin-left: 8px;
        width: auto;
      }

      .searchIconWrapper {
        padding: 0 1rem;
        height: 100%;
        position: absolute;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black;
      }

      .inputContainer {
        width: 100%;
        line-height: 1.4375em;
        letter-spacing: 0.00938em;

        .inputBase {
          width: 100%;
          color: inherit;
          padding: 8px 8px 8px 20px;
          padding-left: calc(1em + 32px);
          transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          font: inherit;
          letter-spacing: inherit;
          border: 0;
          background: none;

          @media (min-width: 600px) {
            width: 12ch;

            &:focus {
              width: 20ch;
              outline: none;
            }
          }
        }
      }
    }

    .logoutContainer {
      flex-direction: column;
      padding: 0 3rem;

      .logoutBtn {
        color: #383434;
        background-color: #8f8a8a69;
      }
    }
  }
}

.errMessage {
  color: $red;
  font-size: 10px;
  text-align: center;
}

.appSelect {
  margin-top: $thicknessUltraThick !important;
  div {
    background-color: $primaryColor5;
    color: $white;
    height: $thicknessThick*5;
  }
  label {
    color: $white;
  }
  svg {
    color: $white;
  }
}


.editionSelect {
  div {
    background-color: $primaryColor5;
    color: $white;
    height: $thicknessThick*5;
  }
  label {
    color: $white;
  }
  svg {
    color: $white;
  }
}