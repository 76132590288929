@import '../../styles/cssConfig.scss';
@import '../../styles.module.scss';


.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-height: 42rem;
  padding: $thicknessUltraThick;
  border: 0;
  background-color: $primaryColor4;
  color: $white;
  border-radius: $thicknessMedium;
}

.noPadding {
  padding: 0 !important;
}

.modalSection {
  border: 0;
  box-shadow: 0px $thicknessMedium 8px $transparentBlack;
}

h2.modalTitle {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

div.modalContent {
  margin-bottom: 1rem;
  font-family: $fontFamily;
}

.centered {
  display: flex;
  justify-content: center;
}

.modalActions {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding: $thicknessUltraThick;

  .modalConfirmContainer {
    display: flex;
    flex-direction: row-reverse;

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: right;
    }
  }

  .modalButton {
    border: $border-style;
    padding: $thicknessMedium $thicknessUltraThick;
    color: $white;
    font-weight: bold;

    svg {
      font-size: $fontSizeExtraLarge;
      padding: 0 0 0 $thicknessMedium;

    }

    &.okButton {
      border: none;
      color: $white;
      background-color: $primaryColor1;

      &:disabled {
        background-color: $containerBgColor;
        color: $primaryColor3;
      }
    }

    &.deleteButton {
      border: $thicknessUltraThin solid $red;
      color: $red;
      background: none;
    }

    &.cancelButton {
      border-color: rgba($primaryColor7, .5);
      color: $primaryColor7;

      &:disabled {
        border-color: grey;
        color: grey;
      }
    }
  }
}

.hasBorderHeader {
  border-bottom: $thicknessUltraThin solid rgba($color: $white, $alpha: 0.1);
}

.hasBorderFooter {
  border-top: $thicknessUltraThin solid rgba($color: $white, $alpha: 0.1);
}

.modalHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $standardPadding $thicknessUltraThick;

  .modalHeaderTextContainer {
    display: flex;
    align-items: center;

    svg {
      font-size: xx-large;
      margin-right: $thicknessUltraThick;
    }
  }

  .modalHeaderButtonContainer {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .modalHeaderCloseButton {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: contents;
    color: $white
  }
}

.modalFooterContainer {
  padding: $standardPadding;

  .modalConfirmContainer {
    display: flex !important;
    flex-direction: row-reverse !important;

    .modalConfirmButton {
      color: $black;
      border-radius: $thicknessMedium;
      text-decoration: capitalize;
      padding: $thicknessMedium $thicknessUltraThick;

      svg {
        margin-left: $thicknessMedium;
      }
    }
  }

  .modalCancelContainer {
    display: flex;
    flex-direction: row;

    .modalCancelButton {
      border-radius: $thicknessMedium;
      text-decoration: capitalize;
      padding: $thicknessMedium $thicknessUltraThick;

      svg {
        margin-left: $thicknessMedium;
      }
    }
  }
}