@import "../../styles/cssConfig.scss";

.filterModal {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: $thicknessMedium $thicknessUltraThick;

  label {
    color: $white;
    text-transform: capitalize;
  }

  fieldset {
    border: $thicknessThin solid $white;

    legend {
      color: $white;
    }
  }

  .filterError {
    color: $red;
  }

  .filterByDate {
    width: 100%;
    height: $paperFoldThick;
    color: currentColor;
    background-color: transparent;
    border-radius: 2 * $thicknessThin;
    border: $thicknessUltraThin solid rgba($white, 0.23);
    text-align: center;
    padding: $thicknessThick 0;
  }

  .filterByDateContainer {
    width: 100%;
    display: flex;
    flex-flow: column;
  }
}