@import "../../styles/cssConfig.scss";

.item-renderer {
  position: relative;
  width: 200px;
  height: 200px;
  background: $primaryColor6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $thicknessThick;

  border: 1px solid $black;
  border-radius: $thicknessMedium;
  padding: $thicknessThick;
  margin: $thicknessThick;

  .background {
    position: relative;
    padding: $thicknessThin;
    width: calc(100% - $thicknessThick);
    height: calc(100% - $thicknessThick);

    .thumbnail {
      max-height: 100%;
      max-width: 100%;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }

  .item-overlay {
    position: absolute;
    z-index: 2;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    border-radius: inherit;

    .asset-header {
      display: flex;
      background-color: $transparentBlack;

      .grid-last-modified-date {
        color: $white;
        text-align: center;
        font-size: $fontSizeSmall;
        font-weight: $fontWeightLight;
        margin: $thicknessUltraThick 0 0 $paperFoldThick;
      }

      .asset-option {
        right: 0;
        position: absolute;
        padding: $thicknessThick;
        border-radius: 0px;
      }

      .options-active {
        color: $primaryColor7;
      }

      .options-inactive {
        color: $grey;
      }
    }

    .select-checkbox {
      margin-right: $thicknessThick;
      display: flex;
      justify-content: flex-start;
      border-radius: 0;
      border-top-left-radius: inherit;
      width: 4 * $thicknessThick;
    }

    .asset-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: $greyTransparent;
      color: $white;
      border-radius: 0;
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
      padding: $thicknessMedium;
      padding-left: $thicknessThick;

      h3 {
        margin: 0;
        font-size: $fontSizeLarge;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100% !important;
        font-weight: 400;
      }

      p {
        margin: $thicknessThin 0;
        font-size: $fontSizeMedium;
      }

      div {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}

.grid-item {
  flex-direction: column;
  width: 200px;
}

.list-item {
  flex-direction: row;
  width: 100%;
}

.list-item-renderer {
  position: relative;
  background: $primaryColor6;
  align-items: center;
  justify-content: center;
  margin: $thicknessMedium $thicknessThick;
  border: 1px solid $black;

  .list-asset-info {
    background-color: $primaryColor6;
    color: $white;
    justify-content: center;
    align-items: center;

    .list-asset-title {
      font-size: $fontSizeMedium;
      font-weight: $fontWeightLight;
      color: $assetTitleColor;
    }

    .list-asset-description {
      font-size: $fontSizeLarge;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: $width100;
    }

    .select-checkbox {
      background: $transparentBlack;
      border-radius: 0;
    }

    .list-thumbnail {
      width: 100%;
      height: 100%;
    }

    .content-center {
      justify-content: center;
      align-items: center;

      button {
        background: $transparentBlack;
        border-radius: 0;
        float: right;
      }

      .options-active {
        color: $primaryColor7;
      }

      .options-inactive {
        color: $grey;
      }
    }

    .padding-thick {
      padding: $thicknessThick;
    }
  }
}

.mirrored {
  transform: scaleX(-1) !important;
}

.skeleton-thumb-placeholder {
  width: 100%;
  height: 100% !important;
}

.hide-thumbnail {
  display: none;
}

.show-thumbnail {
  display: block;
}