@import "../../../styles/cssConfig.scss";

.video-selector-container {
	display: flex;
	column-gap: $thicknessThick;

	.selected-video {
		flex: 1;
		background-color: $white;
		box-shadow: inset 0 0 5px -1px rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			max-width: 100%;
		}
	}

	.video-picker {
		flex: 1;
		padding-top: $thicknessThin;

		> div {
			display: flex;
			justify-content: space-between;
			align-items: center;

			>div{
				width: 100%;
			}

		}
		> div:not(:last-child),
		> select:not(:last-child) {
			margin-bottom: $thicknessThick;
		}

		.MuiAutocomplete-root {
			margin-top: $thicknessThick;
			width: 100%;
		}
	}
}

.thumbnail-image {
	width: $size50;
	height: $size50;
	margin-right: $thicknessThick;
}
