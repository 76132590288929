@import './styles/cssConfig.scss';

.mainContainer {
    font-family: $fontFamily;
    height: 95vh;
    header {
        background-color: $primaryColor7;
    }
}

.moduleMainContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.moduleContainer {
    margin: 8vh 0;
    width: 40%;
    color: $white;
}

.mt2 {
    margin-top: 2rem;
}

.btnContainer {
    margin-top: 1.5rem;
    text-align: end;
}

.width100 {
    width: 100%;
}

.standardBoxShadow {
    box-shadow: $shadowStyle1;

}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.customLoading {
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customButton {
    color: $logoutBtnColor;
    background-color: $white;
}

.customButton:hover {
    color: $white !important;
}

.cursor-pointer {
    cursor: pointer;
}