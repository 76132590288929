@import '../../styles/cssConfig.scss';

.customFooter {
    position:fixed;
    left:0;
    bottom:0;
    right:0;
    background-color: #272727;
    // height: 5vh;
    color: $white;
    z-index: 100;
}