@import "../../../styles/cssConfig.scss";

:global {
	.ql-editor {
		height: 200px;
	}
	.quill {
		border: none;
	}
	.ql-toolbar.ql-snow {
		padding: 0;
		border: none;

		.ql-formats {
			margin-right: $thicknessThick;
			border: $border-style;
			border-radius: $thicknessMedium;
			margin-bottom: $thicknessThick;

			button {
				&.ql-active {
					background-color: lightgray;
				}

				&:first-child {
					border-top-left-radius: inherit;
					border-bottom-left-radius: inherit;
				}

				&:last-child {
					border-top-right-radius: inherit;
					border-bottom-right-radius: inherit;
				}
			}
		}
	}

	.ql-container {
		border: $border-style !important;
		border-radius: $thicknessMedium;
	}
}
