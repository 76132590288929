@import "../../../styles/cssConfig.scss";

.add-edit-article-overlay {
	position: absolute;
	top: $headerHeight;
	left: 0;
	width: 100%;
	height: calc(100% - $headerHeight - $bottomHeight);
	display: flex;
	z-index: 10;
	align-content: center;
	background: $primaryColor9;

	.add-edit-article-container {
		background-color: $white;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		position: absolute;
		left: 5 * $thicknessThick;
		top: 5 * $thicknessThick;
		width: calc(100% - 2 * (5 * $thicknessThick));
		height: calc(100% - 2 * (3 * $thicknessUltraThick) + $thicknessUltraThin);
		display: flex;
		flex-direction: column;
		border-radius: $thicknessMedium;

		.title-bar {
			border-bottom: $thicknessUltraThin solid $primaryColor9;
			padding: $thicknessMedium $thicknessThick;
			display: flex;
			justify-content: space-between;
		}

		.article-content {
			overflow-y: auto;
			padding: $thicknessThick;
			padding-bottom: 0;

			>div:not(:last-child) {
				margin-bottom: $thicknessThick;
			}

			.header-bar {
				display: flex;
				justify-content: space-between;

				>div {
					display: flex;
					column-gap: $thicknessThick;
				}
			}

			.add-edit-article-pane {
				display: flex;
				flex-direction: row-reverse;
				column-gap: $thicknessThick;
				justify-content: center;

				>div {
					width: calc(50% - 2 * $thicknessThick);
					border: $border-style-thick;
					display: flex;
					flex-direction: column;

					&.edit-column {
						> :last-child {
							margin-top: auto;
						}
					}

					>* {
						border: 0;
						border-bottom: $border-style-thick;
						padding: $thicknessThick;
					}

					> :first-child {
						padding: (2 * $thicknessThick) $thicknessThick;
					}

					> :nth-last-child(2),
					> :last-child {
						border-bottom: 0;
					}

					>.header {
						width: calc(100% - 2 * $thicknessThick);
						height: 2 * $thicknessThick;
						color: $black;
						display: flex;
						justify-content: space-between;
						align-items: center;
						column-gap: $thicknessThick;

						.edit-warning {
							display: flex;
							align-items: center;
							gap: $thicknessThick;
							background: $primaryColor11;
							width: 100%;
							padding: $thicknessThick 2 * $thicknessThick;
							border-radius: $thicknessMedium;
							color: $white;
							font-weight: bold;
						}

						.form {
							width: 170px;
							min-width: 170px;
						}
					}

					>.common-components {
						display: flex;
						flex-direction: column;
						row-gap: $thicknessThick;
					}

					.edit-icon {
						color: $primaryColor4;
						margin-left: $thicknessMedium;
					}

					.selected-components {
						display: flex;
						flex-direction: column;

						>div {
							margin-bottom: $thicknessThick;
							display: grid;

							.helper-buttons {
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								gap: $thicknessThick;

								>button {
									width: (3 * $thicknessThick);
									padding: 0;
									min-width: auto;
								}
							}

							&.helpers {
								grid-template-columns: auto (3 * $thicknessThick);
								column-gap: $thicknessThick;
							}
						}

						>div:last-child {
							margin-bottom: 0;
						}
					}

					.article-vit-selector {
						display: flex;
						align-items: center;

						column-gap: $thicknessThick;

						justify-content: space-between;
						background: $primaryColor8;
						padding: 0;

						border-top: $border-style-thick;
					}
				}
			}
		}

		.add-edit-article-action-buttons {
			display: flex;
			column-gap: $thicknessThick;
			justify-content: center;

			justify-content: space-between;
			padding: $thicknessThick (
				2 * $thicknessThick
			);
		margin-top: auto;

		>div {
			display: flex;
			column-gap: 2 * $thicknessThick;
		}
	}
}
}

.confirmation-modal {
	margin: 0;
	padding: $thicknessMedium $thicknessUltraThick;
	text-align: left;
	width: 100%;

	p {
		margin: 0;
		padding: 0;
	}
}

.tableLogContainer {
	height: $thicknessMegaThick * 18;
	display: flex;
	align-items: left;
	flex-direction: column;
	width: 100%;
	padding: $standardPadding;

	thead {
		background: none;
	}

	.tableContainer {
		height: $thicknessThick * 33;
		overflow-y: auto;
	}

	.changeLogRow {
		td {
			border: 0px;
			color: $white;
			padding: $thicknessThick 0;
		}

		th {
			border: 0px;
			color: $white;
			padding: $thicknessThick 0;
		}
	}

	.userCell {
		width: $thicknessMegaThick * 10;
		display: block;
		text-overflow: ellipsis;
		overflow-x: hidden;
	}

	.status {
		text-transform: capitalize;
	}
}

.customLoading {
	height: 83vh;
	display: flex;
	justify-content: center;
	align-items: center;
}