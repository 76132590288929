@import "../../styles/cssConfig.scss";

.listContainer {
  overflow: auto;
  height: 80vh;
  width: 100%;
  max-width: 360px;
  padding-top: 0px !important;
  color: $white;
}

.defaultMenu {
  color: $black;
  background-color: $primaryColor3;
}

.selectedMenu {
  color: $white !important;
  background-color: $primaryColor1 !important;
  svg {
    color: $white !important;
  }
}

.pl4 {
  padding-left: 2rem !important;
}

.mr2 {
  margin-right: 1rem !important;
}

.leftMenu {
  width: 250px;
  position: absolute;
  height: -webkit-fill-available;
  background-color: $primaryColor4;
  background-image: $gradientStyle1;
  z-index: 1;
}

.logoutContainer {
  flex-direction: row;
  padding: 0;
  display: flex;
  gap: $thicknessThick;
}

.flexGrow1 {
  flex-grow: 1;
  display: flex;
}

.floatRight {
  position: absolute;
  right: $thicknessUltraThick;
}

.headerContainer {
  background: $primaryColor1 !important;

  .headerButton {
    background-color: none;
    border: $thicknessUltraThin solid $white;
    color: $white;
    padding: 0 $thicknessThick * 2;

    &:hover {
      background-color: none;
      border: $thicknessUltraThin solid $white;
    }
  }

  .languageSelect {
    width: auto;

    label {
      color: $white;
    }
  }

  .headerSelect {
    background: none;
    color: $white;
    padding: 0 $thicknessUltraThick;
    border-color: rgba($color: $white, $alpha: 1.0);

    &:hover {
      background-color: none;
    }

    fieldset {
      background-color: none;
      background: none;
      border-color: rgba($color: $white, $alpha: 1.0);
      color: $white !important;

      &:visited,
      &:focus {
        color: $white;
      }
    }

    svg {
      color: $white;
    }
  }

  .applicationName {
    width: 600px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    max-width: fit-content;
  }
}