// styles.module.scss

@import "../../styles/cssConfig.scss";

.customTable {
  min-width: 750px;
}

.table_row {
  cursor: pointer;
}

.typography {
  color: inherit;
}

.paper {
  width: 100%;
  margin-bottom: 2rem;
}

.list {
  width: 100%;
  max-width: 360px;
  background-color: var(--background-paper);
}

.toolbar {
  background-color: $primaryColor5;
  background-image: $gradientStyle1;
  padding-left: $thicknessUltraThick !important; 
  padding-left: $thicknessUltraThick !important;
}
