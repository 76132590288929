@import "../../../styles/cssConfig.scss";

.article-renderer {
	.title {
		font-size: $fontSizeMegaLarge;
	}
	.sub-title {
		font-size: $fontSizeUltraLarge;
	}
	div > video, div > img {
		max-width: $height400;
	}

	pre {
		background-color: #23241f;
		color: #f8f8f2;
		overflow: visible;
		white-space: pre-wrap;
		white-space-collapse: preserve;
		margin-bottom: $thicknessMedium;
		margin-top: $thicknessMedium;
		padding: $thicknessMedium $thicknessThick;
	}

}


