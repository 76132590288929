@import "../../styles/cssConfig.scss";

.table_container {
  width: calc(100% - 2* $thicknessMegaThick);
  height: 100%;
  margin: auto;
  padding-bottom: 5vh;
}

.alignCenter {
  text-align: center;
  max-height: 87vh;
  min-height: 50vh;
  overflow-y: auto;
}
.headline {
  color: $white;
  text-align: left;
  background-color: $primaryColor6;
  margin-top: $thicknessUltraThick;
  margin-left:  $thicknessMegaThick;
  margin-right:  $thicknessMegaThick;
  padding-left: $thicknessUltraThick; 
  min-height: calc(4* $thicknessUltraThick);
  line-height: calc(4* $thicknessUltraThick);
}

.userDataGrid {
  min-height: 100px;
  margin: 15px 0;
  .css-74bi4q-MuiDataGrid-overlayWrapper {
    min-height: 100px;
  };
  background-color: $primaryColor4;
  button {
    margin: $thicknessMedium;
  }
  border: none !important;
  border-radius: 0 !important;
}

.gridCell {
  outline: none !important;
  &:focus {
    outline: none !important;
  }
  &.MuiDataGrid-cell {
    outline: none !important;
    &:focus {
      outline: none !important;
    }
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.4rem;
}

.listTitle {
  font-size: 1px !important;
}

.gridCellExpand {
  align-items: center;
  line-height: 24px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  white-space: wrap;

  .gridCellExpandCell {
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
  }

  .gridCellExpandValue {
    white-space: wrap;
  }
}

.deletedUser {
  font-weight: bold !important;
}

.modalContent {
  position: relative;
  display: flex;
  text-align: center;
  overflow-y: auto;
  max-height: $thicknessUltraThick * 30;
  justify-content: center;
  width: 100%;
}
